import { render, staticRenderFns } from "./IngresosComponent.vue?vue&type=template&id=365a226e&scoped=true"
import script from "./IngresosComponent.vue?vue&type=script&lang=js"
export * from "./IngresosComponent.vue?vue&type=script&lang=js"
import style0 from "./IngresosComponent.vue?vue&type=style&index=0&id=365a226e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365a226e",
  null
  
)

export default component.exports